// Imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// CSS
import * as Styles from './css/Search.module.css';

// Icons
import SearchIcon from '../assets/images/icons/icon_search.svg';

// ======== Component ========
const Search = props => {
	const {
		searchAction,
		maxWidth,
	} = props;

	// State
	const [searchText, setSearchText] = useState('');

	const formSubmit = e => {
		e.preventDefault();
		searchAction(searchText);
	};

	return (
		<div className={`flex flex-row form-text ${ Styles.search }`} style={{ maxWidth }}>
			<form
				className={Styles.form}
				style={{ maxWidth }}
				onSubmit={formSubmit}
			>
				<input
					type={'text'}
					value={searchText}
					onChange={e => { setSearchText(e.target.value); }}
					className={'form-text'}
					style={{ maxWidth }}
					aria-label={'Input search text'}
				/>
				<button
					type={'submit'}
					className={Styles.searchButton}
					onClick={formSubmit}
					aria-label={'submit search'}
				>
					<SearchIcon className={Styles.searchIcon} />
				</button>
			</form>
			{searchText.length <= 0 && <p className={Styles.searchPlaceholder}>Search</p>}
		</div>
	);
};

// Prop validation
Search.propTypes = {
	searchAction: PropTypes.func.isRequired, // function to run when submitting the search
	maxWidth: PropTypes.string, // change the maxwidth with any unit; default 250px
};

Search.defaultProps = {
	maxWidth: '250px',
};

export default Search;
