// Imports
import React from 'react';
import PropTypes from 'prop-types';

// CSS
import * as Styles from './css/Form.module.css';

// ======== Component ========
const Form = props => {
	const {
		submitAction,
		styles,
		maxWidth,
		children,
	} = props;
	return (
		<form
			noValidate
			onSubmit={e => {
				e.preventDefault();
				submitAction();
			}}
			style={{ ...styles, maxWidth }}
			className={Styles.form}
		>
			{children}
		</form>
	);
};

/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */

// Prop validation
Form.propTypes = {
	children: PropTypes.node.isRequired, // form sections
	submitAction: PropTypes.func.isRequired, // the action that fires when the form is properly filled out and submitted
	maxWidth: PropTypes.string, // maxwidth
	styles: PropTypes.object, // additional custom CSS for the form
};

Form.defaultProps = {
	maxWidth: '400px',
};

export default Form;
