// Imports
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

// CSS
import * as Styles from './css/Input.module.css';

// ======== Component ========
const Input = props => {
	const {
		label,
		value,
		type,
		onChange,
		styles,
		maxWidth,
		error,
		errorMessage,
	} = props;
	// State
	const [inputFocus, setInputFocus] = useState(false);

	// Ref
	const input = useRef(null);

	const focusOnInput = () => {
		input.current.focus();
	};

	return (
		<div className={`flex flex-ai-fs ${ Styles.inputContainer }`}>
			<p
				role={'presentation'}
				onClick={focusOnInput}
				className={`form-text ${ Styles.inputLabel } ${ (inputFocus || value) ? Styles.inputLabelMoved : null }`}
			>
				{label}
			</p>
			<input
				ref={input}
				type={type}
				value={value}
				onChange={onChange}
				className={`form-text ${ Styles.inputField }`}
				style={error ? { ...styles, maxWidth, borderColor: '#C30000' } : { ...styles, maxWidth }}
				onFocus={() => { setInputFocus(true); }}
				onBlur={() => { setInputFocus(false); }}
			/>
			{error && <p className={`form-text ${ Styles.error }`}>{errorMessage}</p>}
		</div>
	);
};

/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */

// Prop validation
Input.propTypes = {
	value: PropTypes.string.isRequired, // value from parent
	onChange: PropTypes.func.isRequired, // onchange function that updates the value
	label: PropTypes.string.isRequired, // animated label
	type: PropTypes.string, // input type "text", "password", etc.
	error: PropTypes.bool, // bool from parent if field is incorrectly filled out
	errorMessage: PropTypes.string, // error message that is shown when field is incorrectly filled out
	maxWidth: PropTypes.string, // change the maxwidth with any unit; default 400px
	styles: PropTypes.object, // additional custom css props
};

// Default input is a 400px wide text input that is required
Input.defaultProps = {
	maxWidth: '400px',
	type: 'text',
};

export default Input;
