import fetch from 'isomorphic-fetch';

const BB_LOCATION_API_URL = 'https://websiteapis.azurewebsites.net/BBCountry/Location';

/**
 * Check & set if user is in a distribrutor country
 * @param {array} wpDistributors - list of distributors managed in Wordpress
 * @param {function} setDistributor - store-context funciton to set the distributor (bool) variable
 */

// const [distributor, setDistributor] = React.useState(false);

export const resolveDistributor = (wpDistributors, setDistributor) => {
	let parsedDistrubutorData;
	const localStorageKey = 'bb_distributor';
	const existingDistributorData = localStorage.getItem(localStorageKey) || null;

	// Check if local storage variable is past the expiration date

	const isExpired = expAt => {
		return expAt < Date.now();
	};

	// Check if country is in list of distributors (Wordpress)

	const isDistributor = iso => {
		return wpDistributors?.some(dis => {
			return dis.toUpperCase() === iso.toUpperCase().trim();
		});
	};

	// Parse JSON data

	if (existingDistributorData) {
		try {
			parsedDistrubutorData = JSON.parse(existingDistributorData);
		} catch {
			// eslint-disable-next-line
			console.error('Error parsing distributor data JSON.');
		}
	}

	// If local storage data exists and is not expired

	if (parsedDistrubutorData && !isExpired(parsedDistrubutorData?.expiresAt)) {
		// If country is in the list of distributors, change distributor flag to true

		if (isDistributor(parsedDistrubutorData?.countryIso)) {
			setDistributor(true);
		}
	} else {
		// Otherwise fetch new location data from api

		fetch(BB_LOCATION_API_URL, {
			headers: {
				Accept: 'application/json',
			},
		})
			.then(async response => {
				switch (true) {
				//	HTTP 2xx
				case /^2\d{2}/.test(response.status):
					return response.json();
				//	HTTP xxx
				default:
					const message = await response.text(); // eslint-disable-line
					throw new Error(`We could not get geolocation data: ${ message }`);
				}
			})
			.then(responseData => {
				if (!responseData) return;

				const plus1Day = 1000 * 60 * 60 * 24;
				const { country, country_iso: countryIso } = responseData;
				const expiresAt = Date.now() + plus1Day;

				const data = {
					country: country || '',
					countryIso: countryIso || '',
					expiresAt,
				};

				// Set new local storage data

				localStorage.setItem(localStorageKey, JSON.stringify(data));

				// Update distributor flag

				if (isDistributor(countryIso)) {
					setDistributor(true);
				}
			})
			.catch(error => {
				// eslint-disable-next-line
				console.error(error);
			});
	}
};

export const isExternalUrl = url => {
	let isExternal = false;

	if (url && (url.includes('//') || url.match(/((^(mailto|tel|sms|mms|affirm):)|www.)/) || url.includes('#'))) {
		isExternal = true;
	}

	return isExternal;
};
