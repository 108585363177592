// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-room-js": () => import("./../../../src/pages/press-room.js" /* webpackChunkName: "component---src-pages-press-room-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-category-page-template-js": () => import("./../../../src/templates/CategoryPageTemplate.js" /* webpackChunkName: "component---src-templates-category-page-template-js" */)
}

