// Imports
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// CSS
import * as Styles from './css/Textarea.module.css';

// ======== Component ========
const Textarea = props => {
	const {
		value,
		label,
		onChange,
		styles,
		maxWidth,
		rows,
		error,
		errorMessage,
	} = props;
	// State
	const [textAreaFocus, setTextAreaFocus] = useState(false);
	const [textAreaScrolled, setTextAreaScrolled] = useState(false);

	// Ref
	const textArea = useRef(null);

	const focusOnTextArea = () => {
		textArea.current.focus();
	};

	// Scroll
	const handleScroll = () => {
		if (textArea.current.scrollTop > 0) {
			setTextAreaScrolled(true);
		} else {
			setTextAreaScrolled(false);
		}
	};

	useEffect(() => {
		textArea.current.addEventListener('scroll', handleScroll);
	}, []);

	return (
		<div className={`flex flex-ai-fs ${ Styles.textAreaContainer }`}>
			<p
				role={'presentation'}
				onClick={focusOnTextArea}
				className={`
					form-text 
					${ Styles.textAreaLabel } 
					${ (textAreaFocus || value) ? Styles.textAreaLabelMoved : null } 
					${ textAreaScrolled ? Styles.textAreaScrolled : null }`}
			>
				{label}
			</p>
			<textarea
				ref={textArea}
				value={value}
				onChange={onChange}
				className={`form-text ${ Styles.textAreaField }`}
				style={error ? { ...styles, maxWidth, borderColor: '#C30000' } : { ...styles, maxWidth }}
				onFocus={() => { setTextAreaFocus(true); }}
				onBlur={() => { setTextAreaFocus(false); }}
				rows={rows}
			/>
			{error && <p className={`form-text ${ Styles.error }`}>{errorMessage}</p>}
		</div>
	);
};

/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */

// Prop validation
Textarea.propTypes = {
	value: PropTypes.string.isRequired, // value from parent
	onChange: PropTypes.func.isRequired, // onchange function that updates the value
	label: PropTypes.string.isRequired, // animated label
	error: PropTypes.bool, // bool from parent if field is incorrectly filled out
	errorMessage: PropTypes.string, // error message that is shown when field is incorrectly filled out
	maxWidth: PropTypes.string, // change the maxwidth with any unit; default 400px
	styles: PropTypes.object, // additional custom css props
	rows: PropTypes.number, // how many rows of text
};

// Default textarea is a 400px wide textarea input that has 4 rows and is not required
Textarea.defaultProps = {
	maxWidth: '400px',
	rows: 4,
};

export default Textarea;
