// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// CSS
import * as Styles from './css/Button.module.css';

// Icons
import ArrowIcon from '../assets/images/icons/icon_button_arrow.svg';

// ======== Component ========
const Button = props => {
	const {
		backgroundColor,
		type,
		submit,
		disabled,
		action = () => {},
		hoverEffect,
		fullWidth,
		styles,
		textColor,
		text,
		link,
		target,
	} = props;

	let color = '';
	if (backgroundColor === 'navy') {
		color = '#0B213D';
	} else if (backgroundColor === 'green') {
		color = '#BDCC2A';
	} else if (backgroundColor === 'gray') {
		color = '#DBDBDB';
	}

	return (
		<>
			{/* <button> */}
			{type === 'button' && (
				<button
					type={submit ? 'submit' : 'button'}
					disabled={disabled}
					onClick={
						!submit
							? () => {
								action();
							}
							: null
					}
					className={`
						flex 
						flex-row
						button-text 
						${ Styles.buttonStyles }
						${ hoverEffect === 'arrow' ? Styles.buttonArrowHover : Styles.buttonFadeHover }
						${ hoverEffect === 'none' ? Styles.buttonNoHover : null }
						${ disabled ? Styles.disabledButton : null }
						${ fullWidth === true ? Styles.buttonFullWidth : null }
					`}
					style={styles}
				>
					<div
						className={Styles.buttonBackground}
						style={{ backgroundColor: color }}
					/>
					<p
						className={Styles.buttonText}
						style={{ color: textColor === 'black' ? '#1F1F1F' : '#FFFFFF' }}
					>
						{text}
						<ArrowIcon className={Styles.arrowIcon} />
					</p>
				</button>
			)}

			{/* <a> */}
			{type === 'a' && (
				<a
					href={link}
					target={target}
					disabled={disabled}
					className={`
            flex 
            flex-row
            button-text 
            ${ Styles.buttonStyles }
            ${
				hoverEffect === 'arrow'
					? Styles.buttonArrowHover
					: Styles.buttonFadeHover
				}
            ${ fullWidth === true ? Styles.buttonFullWidth : null }`}
					style={styles}
				>
					<div
						className={Styles.buttonBackground}
						style={{ backgroundColor: color }}
					/>
					<p
						className={Styles.buttonText}
						style={{ color: textColor === 'black' ? '#1F1F1F' : '#FFFFFF' }}
					>
						{text}
						<ArrowIcon className={Styles.arrowIcon} />
					</p>
				</a>
			)}

			{/* <Link> */}
			{type === 'link' && (
				<Link
					to={link}
					target={target}
					disabled={disabled}
					className={`
						flex 
						flex-row
						button-text 
          				${ Styles.buttonStyles }
          				${ hoverEffect === 'arrow' ? Styles.buttonArrowHover : Styles.buttonFadeHover }
          				${ fullWidth === true ? Styles.buttonFullWidth : null }
					`}
					style={styles}
				>
					<div
						className={Styles.buttonBackground}
						style={{ backgroundColor: color }}
					/>
					<p
						className={Styles.buttonText}
						style={{ color: textColor === 'black' ? '#1F1F1F' : '#FFFFFF' }}
					>
						{text}
						<ArrowIcon className={Styles.arrowIcon} />
					</p>
				</Link>
			)}
		</>
	);
};

/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */

// Prop validation
Button.propTypes = {
	type: PropTypes.string.isRequired, // "button", "a", "link" (for gatsby navigation)
	text: PropTypes.string.isRequired, // text of the button
	hoverEffect: PropTypes.string, // "arrow", "fade", or "none"
	backgroundColor: PropTypes.string, // accepts hex values or "navy", "green", "gray"
	textColor: PropTypes.string, // "white", "black"
	action: PropTypes.func, // function to run when clicked
	link: PropTypes.string, // href url or Link path
	styles: PropTypes.object, // additional CSS props
	disabled: PropTypes.bool, // disabled button
	fullWidth: PropTypes.bool, // set to true for 100% width
	target: PropTypes.string, // "_blank" or whatever for target=
	submit: PropTypes.bool, // true if a form submit button
};

// default button is blue with white text that does not submit a form; arrow on hover
Button.defaultProps = {
	hoverEffect: 'arrow',
	backgroundColor: 'navy',
	textColor: '#FFFFFF',
	disabled: false,
	fullWidth: false,
	submit: false,
};

export default Button;
